<template src="./WholeMultiselect.html"></template>

<script>
import Multiselect from "vue-multiselect";
export default {
  name: "WholeMultiselect",
  components: { Multiselect },
  props: {
    title: {
      type: String,
      required: false,
      default: "",
    },
    alert: {
      type: Boolean,
      required: false,
      default: false,
    },
    errorMessage: {
      type: String,
      required: false,
      default: "",
    },
    options: {
      type: Array,
      required: true,
      default: () => [],
    },
    placeholder: {
      type: String,
      required: false,
      default: "",
    },
    showIdAndName: {
      type: Boolean,
      required: false,
      default: false,
    },
    showText: {
      type: Boolean,
      required: false,
      default: false,
    },
    showDescription: {
      type: Boolean,
      required: false,
      default: false,
    },
    value: {
      default: "",
    },
    isRequired: {
      type: Boolean,
      required: false,
      default: false,
    },
    trackBy: {
      type: String,
      required: false,
      default: "id",
    },
    defaultLabel: {
      type: Boolean,
      required: false,
      default: false,
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    selectValue({ id, name, text, description }) {
      if (this.showIdAndName) {
        return `${id} - ${name}`;
      }
      if (this.showText) {
        return text;
      }
      if (this.showDescription) {
        return description;
      }
      return name;
    },
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>

<style src="./WholeMultiselect.css"></style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
